
import { defineComponent, onMounted, ref } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import Entitytable from "@/components/EntityTable.vue";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import RequestPayoutModal from "@/components/RequestPayoutModal.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import FilterBox from "@/components/FilterBox.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "payouts-details",
  components: {
    FilterBox,
    Entitytable,
    RequestPayoutModal,
  },
  methods: {
    catchErrors(error) {
      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    updateTransaction(transaction_id, newStatus) {
      this.invoice = "";
      if (newStatus == "processed") {
        Swal.fire({
          title: "Payout Confirmation",
          text: "Please input invoice number to perform this operation.",
          input: "text",
          inputValue: this.invoice,
          customClass: {
            input: "form-control form-control-solid",
          },
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return "Input a valid invoice number";
            }
          },
          preConfirm: (value) => {
            ApiService.put("transactions/" + transaction_id, {
              status: newStatus,
              invoice: value,
            })
              .then((response) => {
                this.refreshData();
              })
              .catch(this.catchErrors);
          },
        });
      } else {
        ApiService.put("transactions/" + transaction_id, {
          status: newStatus,
          invoice: null,
        })
          .then((response) => {
            this.refreshData();
          })
          .catch(this.catchErrors);
      }
    },
    refreshModal() {
      this.modal.show();
    },
    refreshData() {
      this.refreshBalance();
      (this.$refs.txEntities as any).retrieveData();
    },
    resetData() {
      this.modalKey = Math.random().toString();
      alert(this.modalKey);
    },
    getDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getTime(date) {
      return moment(date).format("hh:mm:ss");
    },
    submit(value) {
      ApiService.put("affiliates/", {
        wallet_address: this.wallet,
      }).then((res) => {
        if (res.status == 200) {
          this.refreshBalance();
          Swal.fire({
            text: "Wallet Confirmed!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } else {
          Swal.fire({
            text: "Oops, something went wrong!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
    refreshBalance() {
      this.balanceLoading = true;
      ApiService.get("balance").then((result) => {
        this.balance = result.data;
        this.wallet = result.data?.wallet_address;
      }).finally(() => {
        this.balanceLoading = false;
      });
    },
    exportData() {
      ApiService.downloadQuery("transaction/export", {
        params: {
          filters: JSON.stringify(this.filters),
        },
      }).then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.click();
      });
    },
  },
  data() {
    return {
      balanceLoading: false,
      wallet: null,
      invoice: "",
      modalKey: "",
      balance: {
        available_display: "...",
        hold_display: "...",
        balance_display: "...",
        wallet_address: "...",
      },
      affiliates: "",
      filters: {
        type: "payout",
      },
      columnData: [
        {
          label: "ID",
          name: "id",
        },
        {
          label: "Payment Method",
          name: "payment_method",
        },
        {
          label: "Amount",
          name: "amount_string",
        },
        {
          label: "Status",
          name: "status_string",
        },
      ],
    };
  },
  mounted() {
    const element = document.getElementById(
      "request-payout-modal"
    ) as HTMLElement;
    this.modal = new Modal(element, {});
  this.refreshBalance();
    ApiService.get("affiliates").then((result) => {
      this.affiliates = result.data;
    });
  },
  setup() {
    let modal = ref<Modal>();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Payouts", ["Payouts", "Details"]);
      setCurrentPageTitle("Payouts");
    });

    const store = useStore();

    return { modal, store };
  },
});
